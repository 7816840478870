<template>
  <div class="row mx-0">
    <div class="div-logo">
      <img :src="logo" class="logo" alt="" />
    </div>

    <div id="user-img" v-if="msg == 'success'">
      <img :src="this.image(this.identity.image)" alt="imagen de usuario" />
    </div>

    <h1 v-if="msg == 'success'" id="user-name-img" class="color-6">
      ¡Hola, <span> {{ name | split }} </span> !
    </h1>
    <h1 v-else id="user-name" class="color-6">
      ¡Hola, <span> {{ name | split }} </span> !
    </h1>

    <div class="center pb-4">
      <b-btn v-b-modal.modal-logout variant="outline-secondary" class="logout"
        ><p id="" class="">{{ name }}</p>
        Cerrar Sesión <img src="../assets/Group.png" alt=""
      /></b-btn>
      <b-modal
        id="modal-logout"
        centered
        hide-footer
        hide-header
        body-class="p-0"
        dialog-class="px-5"
        content-class="border-0"
      >
        <div class="d-block text-center">
          <img :src="closeIcon" class="icon3 pt-5" /><br />
          <p class="modalHeader pt-3 color-6">Cerrar sesión</p>
          <p class="modalBody color-grey">¿Estás seguro que deseas salir?</p>
        </div>
        <div class="wrapper">
          <b-button
            class="one py-3 btnleft btns"
            @click="$bvModal.hide('modal-logout')"
            >Cancelar</b-button
          >
          <b-button class="two btnright btns" @click="logout()"
            >Confirmar</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import logo from "../assets/cimarrones/logocima_2.png";
import icon_mas from "../assets/icon-mas.png";
import i_logout from "../assets/iconocerrar.png";
import usericon from "../assets/usuarios.png";

export default {
  name: "Header",
  props: {
    name: {
      required: true,
    },
  },
  data() {
    return {
      logo: logo,
      usericon: usericon,
      icon_mas: icon_mas,
      closeIcon: i_logout,
      msg: "success",
    };
  },
  computed: {
    token() {
      return this.$store.getters["admin/getToken"];
    },
    identity() {
      return this.$store.getters["admin/getIdentity"];
    },
    menu_option() {
      return this.$store.getters["main/getHistoryOption"];
    },
    url() {
      return this.$store.getters["main/baseURL"];
    },
  },
  methods: {
    logout: function () {
      delete localStorage.token;
      delete localStorage.ath;
      this.wait();
    },
    wait: function () {
      setTimeout(() => this.$router.push("/"), 200);
    },
    image: function (img) {
      let src = this.url + "/get-user-image/" + img;
      this.status(img);
      return src;
    },
    status: async function (img) {
      let userimg = "";
      try {
        userimg = await this.$store.dispatch("main/getImage", {
          image: img,
          option: "user",
        });
        if (userimg.data.status == "error") {
          this.msg = "error";
        } else {
          this.msg = "success";
        }
      } catch (err) {
        this.msg = "error";
      }
    },
  },
  filters: {
    split: (value) => {
      if (!value) return "";
      value = value.toString();
      let splitStr = value.toLowerCase().split(" ");
      let len = splitStr.length;
      let i;

      for (i = 0; i < len; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      let nameCap = splitStr.join(" ");
      let newName = nameCap.split(" ").slice(0, -1).join(" "); //cantidad de palabras quitadas.

      return newName;
    },
  },
};
</script>




<template>
  <div id="sidemenu" class="">
    <p class="ahome " @click="home()">HOME</p>
    <ul class="admin-menu color-bg-8" v-if="indexsec == 200000">
      <!--  <li   class="color-4 "
                    v-for="(option, index) in options" :key="option.id"
                    @click="activate(index),  getAllInfo(option), setHistoryOption(option),setHistoryIndex(index);" 
                    :class="{ active : elementIndex == index}"            
                    >  

                    <span class="text-menu"> {{option}} </span> </li>
                    -->

      <div
        v-if="
          identity().role == 'admin' ||
            identity().role == 'Legrafica' ||
            identity().role == 'legrafica'
        "
      >
        <li
          class="color-4 "
          @click="
            activate(0),
              getAllInfo('Calendario'),
              setHistoryOption('Calendario'),
              setHistoryIndex(0)
          "
          :class="{ active: elementIndex == 0 }"
        >
          <span class="text-menu">Calendario</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(1),
              getAllInfo('Jugadores'),
              setHistoryOption('Jugadores'),
              setHistoryIndex(1)
          "
          :class="{ active: elementIndex == 1 }"
        >
          <span class="text-menu">Jugadores</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(2),
              getAllInfo('Equipos'),
              setHistoryOption('Equipos'),
              setHistoryIndex(2)
          "
          :class="{ active: elementIndex == 2 }"
        >
          <span class="text-menu">Equipos</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(3),
              getAllInfo('Posiciones'),
              setHistoryOption('Posiciones'),
              setHistoryIndex(3)
          "
          :class="{ active: elementIndex == 3 }"
        >
          <span class="text-menu">Posiciones</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(4),
              getAllInfo('Tienda'),
              setHistoryOption('Tienda'),
              setHistoryIndex(4)
          "
          :class="{ active: elementIndex == 4 }"
        >
          <span class="text-menu">Tienda</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(5),
              getAllInfo('Variantes'),
              setHistoryOption('Variantes'),
              setHistoryIndex(5)
          "
          :class="{ active: elementIndex == 5 }"
        >
          <span class="text-menu">Variantes</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(6),
              getAllInfo('Categorias'),
              setHistoryOption('Categorias'),
              setHistoryIndex(6)
          "
          :class="{ active: elementIndex == 6 }"
        >
          <span class="text-menu">Categorias</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(7),
              getAllInfo('Blog'),
              setHistoryOption('Blog'),
              setHistoryIndex(7)
          "
          :class="{ active: elementIndex == 7 }"
        >
          <span class="text-menu">Blog</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(8),
              getAllInfo('Galeria'),
              setHistoryOption('Galeria'),
              setHistoryIndex(8)
          "
          :class="{ active: elementIndex == 8 }"
        >
          <span class="text-menu">Galeria</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(9),
              getAllInfo('Videos'),
              setHistoryOption('Videos'),
              setHistoryIndex(9)
          "
          :class="{ active: elementIndex == 9 }"
        >
          <span class="text-menu">Videos</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(10),
              getAllInfo('Canales'),
              setHistoryOption('Canales'),
              setHistoryIndex(10)
          "
          :class="{ active: elementIndex == 10 }"
        >
          <span class="text-menu">Canales</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(11),
              getAllInfo('Ligas'),
              setHistoryOption('Ligas'),
              setHistoryIndex(11)
          "
          :class="{ active: elementIndex == 11 }"
        >
          <span class="text-menu">Ligas</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(12),
              getAllInfo('Torneos'),
              setHistoryOption('Torneos'),
              setHistoryIndex(12)
          "
          :class="{ active: elementIndex == 12 }"
        >
          <span class="text-menu">Torneos</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(13),
              getAllInfo('Perfiles'),
              setHistoryOption('Perfiles'),
              setHistoryIndex(13)
          "
          :class="{ active: elementIndex == 13 }"
        >
          <span class="text-menu">Perfiles</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(14),
              getAllInfo('Patrocinadores'),
              setHistoryOption('Patrocinadores'),
              setHistoryIndex(14)
          "
          :class="{ active: elementIndex == 14 }"
        >
          <span class="text-menu">Patrocinadores</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(15),
              getAllInfo('Suscriptores'),
              setHistoryOption('Suscriptores'),
              setHistoryIndex(15)
          "
          :class="{ active: elementIndex == 15 }"
        >
          <span class="text-menu">Suscriptores</span>
        </li>

        <li
          class="color-4 "
          @click="
            activate(16),
              getAllInfo('Usuarios'),
              setHistoryOption('Usuarios'),
              setHistoryIndex(16)
          "
          :class="{ active: elementIndex == 16 }"
        >
          <span class="text-menu">Usuarios</span>
        </li>
        <li
          class="color-4 "
          @click="
            activate(17),
              getAllInfo('Cabeceras'),
              setHistoryOption('Cabeceras'),
              setHistoryIndex(17)
          "
          :class="{ active: elementIndex == 17 }"
        >
          <span class="text-menu">Cabeceras</span>
        </li>
      </div>
      <div v-else-if="identity().role == 'editor'">
        <li
          class="color-4 "
          @click="
            activate(4),
              getAllInfo('Tienda'),
              setHistoryOption('Tienda'),
              setHistoryIndex(4)
          "
          :class="{ active: elementIndex == 4 }"
        >
          <span class="text-menu">Tienda</span>
        </li>
        <li
          class="color-4 "
          @click="
            activate(8),
              getAllInfo('Galeria'),
              setHistoryOption('Galeria'),
              setHistoryIndex(8)
          "
          :class="{ active: elementIndex == 8 }"
        >
          <span class="text-menu">Galeria</span>
        </li>
      </div>
      <div v-else-if="identity().role == 'comunicacion'">
        <li
          class="color-4 "
          @click="
            activate(7),
              getAllInfo('Blog'),
              setHistoryOption('Blog'),
              setHistoryIndex(7)
          "
          :class="{ active: elementIndex == 7 }"
        >
          <span class="text-menu">Blog</span>
        </li>
        <li
          class="color-4 "
          @click="
            activate(8),
              getAllInfo('Galeria'),
              setHistoryOption('Galeria'),
              setHistoryIndex(8)
          "
          :class="{ active: elementIndex == 8 }"
        >
          <span class="text-menu">Galeria</span>
        </li>
      </div>
      <div v-else-if="identity().role == 'multimedia'">
        <li
          class="color-4 "
          @click="
            activate(9),
              getAllInfo('Videos'),
              setHistoryOption('Videos'),
              setHistoryIndex(9)
          "
          :class="{ active: elementIndex == 9 }"
        >
          <span class="text-menu">Videos</span>
        </li>
      </div>
    </ul>
    <ul v-else class="admin-menu color-bg-8">
      <div
        v-if="
          identity().role == 'admin' ||
            identity().role == 'Legrafica' ||
            identity().role == 'legrafica'
        "
      >
        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 0 }"
        >
          <span class="text-menu">Calendario</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 1 }"
        >
          <span class="text-menu">Jugadores</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 2 }"
        >
          <span class="text-menu">Equipos</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 3 }"
        >
          <span class="text-menu">Posiciones</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 4 }"
        >
          <span class="text-menu">Tienda</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 5 }"
        >
          <span class="text-menu">Variantes</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 6 }"
        >
          <span class="text-menu">Categorias</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 7 }"
        >
          <span class="text-menu">Blog</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 8 }"
        >
          <span class="text-menu">Galeria</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 9 }"
        >
          <span class="text-menu">Videos</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 10 }"
        >
          <span class="text-menu">Canales</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 11 }"
        >
          <span class="text-menu">Ligas</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 12 }"
        >
          <span class="text-menu">Torneos</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 13 }"
        >
          <span class="text-menu">Perfiles</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 14 }"
        >
          <span class="text-menu">Patrocinadores</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 15 }"
        >
          <span class="text-menu">Suscriptores</span>
        </li>

        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 16 }"
        >
          <span class="text-menu">Usuarios</span>
        </li>

        <li
        class="color-4 "
        @click="salir()"
        :class="{ active: indexsec == 17 }"
        >
        <span class="text-menu">Cabeceras</span>
        </li>
      </div>
      <div v-else-if="identity().role == 'editor'">
        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 4 }"
        >
          <span class="text-menu">Tienda</span>
        </li>
        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 8 }"
        >
          <span class="text-menu">Galeria</span>
        </li>
      </div>
      <div v-else-if="identity().role == 'comunicacion'">
        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 8 }"
        >
          <span class="text-menu">Galeria</span>
        </li>
        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 7 }"
        >
          <span class="text-menu">Blog</span>
        </li>
      </div>
      <div v-else-if="identity().role == 'multimedia'">
        <li
          class="color-4 "
          @click="salir()"
          :class="{ active: indexsec == 9 }"
        >
          <span class="text-menu">Videos</span>
        </li>
      </div>
    </ul>
    <div class="logo-lg color-bg-6">
      <img
        id="PoweredByLegrafica"
        :src="legrafica"
        alt="Powered By Legrafica"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import legrafica from "../assets/Legrafica.png";
import categorias from "../assets/categorias.png";
import usuarios from "../assets/usuarios.png";
import productos from "../assets/productos.png";
import userService from "../services/user.service";
export default {
  name: "SideMenu",
  props: {
    indexsec: {
      type: Number,
      default: 200000,
    },
  },
  data() {
    return {
      legrafica: legrafica,
      element: 0,
      options: [
        "Calendario",
        "Jugadores",
        "Equipos",
        "Posiciones",
        "Tienda",
        "Variantes",
        "Categorias",
        "Blog",
        "Galeria",
        "Videos",
        "Canales",
        "Ligas",
        "Torneos",
        "Perfiles",
        "Patrocinadores",
        "Suscriptores",
        "Usuarios",
        "Cabeceras",
      ],
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
    };
  },

  created() {
    if (this.indexsec != 200000) {
      let options = {
        index: this.indexsec,
        option: this.getOpt(this.indexsec),
      };
      options = JSON.stringify(options);
      localStorage.setItem("nav", options);
    }
    this.getUsers("usuarios");
    this.getBanners("Banner");
    this.getCategories("Categorías");
  },
  computed: {
    historyOption() {
      return this.$store.getters["main/getHistoryOption"];
    },
    historyIndex() {
      return this.$store.getters["main/getHistoryIndex"];
    },
    elementIndex() {
      if (this.indexsec == 200000) {
        let index = this.$store.getters["main/getHistoryIndex"];
        let option = this.$store.getters["main/getHistoryOption"];
        let options = [
          "Calendario",
          "Jugadores",
          "Equipos",
          "Posiciones",
          "Tienda",
          "Variantes",
          "Categorias",
          "Blog",
          "Galeria",
          "Videos",
          "Canales",
          "Ligas",
          "Torneos",
          "Perfiles",
          "Patrocinadores",
          "Suscriptores",
          "Usuarios",
          "Cabeceras",
        ];

        for (var i = 0; i < options.length; i++) {
          if (option == options[i]) {
            index = i;
          }
        }
        return index;
      } else {
        let index = this.getNav().index;
        let option = this.getNav().option;
        let options = [
          "Calendario",
          "Jugadores",
          "Equipos",
          "Posiciones",
          "Tienda",
          "Variantes",
          "Categorias",
          "Blog",
          "Galeria",
          "Videos",
          "Canales",
          "Ligas",
          "Torneos",
          "Perfiles",
          "Patrocinadores",
          "Suscriptores",
          "Usuarios",
          "Cabeceras",
        ];

        for (var i = 0; i < options.length; i++) {
          if (option == options[i]) {
            index = i;
          }
        }
        return index;
      }
    },
  },
  methods: {
    ...mapActions("users", ["getUsers"]),
    ...mapActions("categories", ["getCategories"]),
    ...mapActions("banner", ["getBanners"]),

    ...mapActions("main", ["getAllInfo"]),
    ...mapActions("main", ["setHistoryOption"]),
    ...mapActions("main", ["setHistoryIndex"]),
    activate: function(index) {
      this.element = index;
    },
    salir: function() {
      this.$router.push("/administrador").catch((err) => {});
    },
    home: function() {
      this.$router.push("/").catch((err) => {});
    },
    getOpt: function(index) {
      let options = [
        "Calendario",
        "Jugadores",
        "Equipos",
        "Posiciones",
        "Tienda",
        "Variantes",
        "Categorias",
        "Blog",
        "Galeria",
        "Videos",
        "Canales",
        "Ligas",
        "Torneos",
        "Perfiles",
        "Patrocinadores",
        "Suscriptores",
        "Usuarios",
        "Cabeceras",
      ];

      return options[index];
    },
    getNav: function() {
      let options = localStorage.getItem("nav");
      // console.log(options)
      if (options == null || options == undefined || options == "") {
        return false;
      } else {
        return (options = JSON.parse(options));
      }
    },
    delItem: function() {
      // console.log('eliminando')
      localStorage.removeItem("nav");
    },
    identity: function() {
      //validar que no entren a la ruta de editar usuarios especificos

      let user = "";
      try {
        let token = userService.getToken();
        user = userService.decode(token);
      } catch (error) {
        console.log(error);
        user = "error";
      }

      if (!user.role) {
        return "error";
      } else {
        return user;
      }
    },
  },
};
</script>
